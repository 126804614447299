$main-menu-item-height: 110px;
$main-menu-width: 120px;
$main-menu-width-lg: 110px;
$main-menu-width-md: 100px;
$main-menu-width-xs: 90px;

$sub-menu-width: 230px;
$sub-menu-width-lg: 230px;
$sub-menu-width-md: 230px;
$sub-menu-width-xs: 230px;

$navbar-height: 100px;
$navbar-height-xs: 70px;
$navbar-height-md: 80px;
$navbar-height-lg: 90px;

$main-margin: 60px;
$main-margin-lg: 50px;
$main-margin-md: 40px;
$main-margin-xs: 15px;

$app-menu-width: 280px;
$main-menu-item-height-mobile: 90px;

$menu-collapse-time: 300ms;
$animation-time-long: 1000ms;
$animation-time-short: 200ms;

$border-radius: 0.1rem;
$border-radius-rounded: 0.75rem;

$theme-color-purple-monster: #922c88;
$theme-color-blue-navy: #00365a;
$theme-color-blue-yale: #145388;
$theme-color-blue-olympic: #008ecc;
$theme-color-green-moss: #576a3d;
$theme-color-green-lime: #6fb327;
$theme-color-orange-carrot: #ed7117;
$theme-color-red-ruby: #900604;
$theme-color-yellow-granola: #c0a145;
$theme-color-grey-steel: #48494b;

$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;

$red-color: #E31837;
$burgendyred-color: #A61242