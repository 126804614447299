.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.droppable {
  padding: 0 0.5rem;
  margin-left: 0.5rem;
  font-size: 1rem;
  display: inline-block;
}

.draggable {
  border: 1px dashed gray;
  background-color: white;
  padding: 0 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: grab;
  float: left;
  touch-action: none;
}

.changed-candidate {
  background-color: yellow;
}

.danger {
  color: red;
}

.green {
  color: green;
}

.processing-candidate {
  background: #fbffb1 !important;
}
